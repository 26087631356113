import { fetchStripeRequirementsStatus } from "network/Account/checkStripeRequirementsStatus";
import {
  getNextRequirementPath,
  queryKeyFetchAccount,
} from "network/Account/fetch";
import { getUserNextRequirementPath } from "network/User/fetchUserWithAuth0UserId";
import { fetchUserPermisions } from "network/User/get";
import { QueryClient } from "react-query";
import {
  HostfiPagePermissions,
  Account,
  HostfiUser,
  HostfiUserPermissions,
} from "typings/shared";

import { getAccessToken } from "./auth";

const authPropToRouteMap: Record<string, string> = {
  dashboard_visibility: "/dashboard",
  transaction_visibility: "/transactions",
  property_visibility: "/properties",
  card_visibility: "/cards",
  payments_visibility: "/payments",
  accounting: "/accounting",
  subaccount_visibility: "/accounts",
};

export const getFirstAuthorizedRoute = (data: HostfiUserPermissions) => {
  const permissions = new HostfiPagePermissions(data);
  const firstVisible = Object.entries(permissions).find(
    ([, value]) => value > 0,
  )?.[0] as keyof HostfiPagePermissions;

  return authPropToRouteMap[firstVisible];
};

export const getRedirect = async () => {
  const data = await fetchUserPermisions();
  return getFirstAuthorizedRoute(data);
};

const redirects: Record<string, () => Promise<string>> = {
  "/return": getRedirect,
  "/login": getRedirect,
  "/welcome": getRedirect,
  "/": async () => (getAccessToken() ? getRedirect() : "/"),
};

/**
 * Runs once when a user first auths, and on every hard load an authed user's
 * client makes
 */
export const determineNextPath = async (
  queryClient: QueryClient,
  route: string,
  account: Account,
  hfUser: HostfiUser,
) => {
  let stripeRequirementsCompleted;
  const { role } = hfUser;

  if (role === "admin") {
    // If our api returns stripe requirements false always check directly with stripe
    if (!account.stripe_requirements_completed) {
      stripeRequirementsCompleted = await fetchStripeRequirementsStatus();
    }

    if (
      !stripeRequirementsCompleted &&
      !account.stripe_requirements_completed
    ) {
      return { nextPath: "/welcome" };
    }

    if (account.remaining_requirements?.filter((item) => !!item).length) {
      const { nextPath, remainingRequirements } = getNextRequirementPath(
        account.remaining_requirements,
        role,
      );
      queryClient.setQueryData(queryKeyFetchAccount, {
        ...account,
        remaining_requirements: remainingRequirements,
      });

      return { nextPath };
    }
  }

  // Check if the user has any remaining requirements
  if (hfUser.remaining_requirements?.filter((item) => !!item).length) {
    const { nextPath } = getUserNextRequirementPath(
      hfUser.remaining_requirements,
    );

    return { nextPath };
  }

  const redirect = redirects[route];

  if (redirect) {
    return {
      nextPath: await redirect(),
    };
  }

  return { nextPath: route };
};
