import React, { useCallback } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, ButtonProps, Tooltip } from "@chakra-ui/react";
import { api } from "network";
import { routePaths } from "network/routes";
import { useRouter } from "next/router";
import { isBrowser } from "utils/env";
import { useIsLoaded } from "utils/useNoSsr";

export const ButtonLogout: React.FC<ButtonProps> = (props) => {
  const handleClick = useCallback(
    () => api.prototype.logout({ returnTo: `${window.location.origin}/login` }),
    [],
  );

  return (
    <Button colorScheme="brand" onClick={handleClick} {...props}>
      Log Out
    </Button>
  );
};

export const ButtonLogin: React.FC<ButtonProps> = (props) => {
  const { loginWithRedirect } = useAuth0();

  const handleClick = () => {
    loginWithRedirect();
  };

  return (
    <Button
      colorScheme="brand"
      onClick={handleClick}
      variant="outline"
      {...props}
    >
      Log in
    </Button>
  );
};

export const ButtonSignUp: React.FC<ButtonProps> = (props) => {
  const { push } = useRouter();
  const handleClick = () => push(routePaths.home);

  return (
    <Button
      colorScheme="brand"
      onClick={handleClick}
      variant="outline"
      {...props}
    >
      Sign up
    </Button>
  );
};

export const NavButton: React.FC<ButtonProps> = (props) => {
  const isLoaded = useIsLoaded();
  const { isAuthenticated = false } = useAuth0();

  if (!isLoaded) return <></>;

  if (isAuthenticated) {
    return <ButtonLogout {...props} />;
  }

  if (isBrowser && /\/login/.test(window.location.pathname)) {
    return <ButtonSignUp {...props} />;
  }

  return <ButtonLogin {...props} />;
};

export function ButtonAddToAppleWallet() {
  return (
    <Tooltip
      hasArrow
      fontSize="14px"
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="5"
      color="gray.500"
      maxWidth="100%"
      placement="top"
      arrowShadowColor="#EAEAEA"
      position="relative"
      p={5}
      label={
        <Box borderRadius="5" position="relative">
          <Box as="p" mb="2">
            To add your card to your Apple Wallet:
          </Box>
          <Box as="ul" listStylePos="inside">
            <li>Open your Apple Wallet App.</li>
            <li>Tap &ldquo;+&rdquo;</li>
            <li>Tap &ldquo;Credit or Debit Card.&rdquo;</li>
            <li>Tap &ldquo;Continue.&rdquo;</li>
            <li>Tap &ldquo;Enter Card Details Manually&rdquo; to fill in.</li>
          </Box>
        </Box>
      }
    >
      <Button variant="outline" _hover={{ bg: "white" }}>
        Add To Apple Wallet
      </Button>
    </Tooltip>
  );
}
