export const isBrowser: boolean = typeof window !== "undefined";

export const posthogReady: boolean =
  isBrowser && !!process.env.NEXT_PUBLIC_POSTHOG_KEY;

export const getEnvVar = (value: string) => {
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }
  if (!Number.isNaN(parseFloat(value))) {
    return parseInt(value, 10);
  }
  return value;
};
