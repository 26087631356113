import { useUserContext } from "context/user";
import { api } from "network";
import { routes } from "network/routes";
import { HostfiUser, UserFlowStep } from "typings/shared";
import { getFirstAuthorizedRoute } from "utils/determineNextPath";

import { useGetUserPermissions } from "./get";

export const queryKeyFetchWithAuth0UserId = "useFetchWithAuth0UserId";

export const fetchUserWithAuth0UserId = (
  auth0_user_id?: string,
): Promise<HostfiUser> =>
  api.post(routes.userFetchWithAuth0UserId, { auth0_user_id });

export const getUserNextRequirementPath = (
  remainingRequirements: UserFlowStep[],
  currentStep?: UserFlowStep,
  permissions?: any,
) => {
  const reqs = remainingRequirements.filter((req) => req !== currentStep);
  const [nextPathStep] = reqs;
  if (nextPathStep) {
    return {
      nextPath: `/signup-user/${nextPathStep?.replace(/_/g, "-")}`,
      nextPathStep,
      remainingRequirements: reqs,
    };
  }

  const firstPath =
    permissions !== undefined
      ? getFirstAuthorizedRoute(permissions)
      : "/dashboard";

  return {
    nextPath: firstPath,
    remainingRequirements: reqs,
  };
};

export const useGetUserNextRequirementPath = (currentStep?: UserFlowStep) => {
  const user = useUserContext();
  const { data: permissions } = useGetUserPermissions();
  const remainingRequirements = user?.remaining_requirements || [];
  return getUserNextRequirementPath(
    remainingRequirements,
    currentStep,
    permissions,
  );
};
