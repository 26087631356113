import { api } from "network";
import { routes } from "network/routes";
import { useHfQuery } from "network/useHfQuery";
import { UseQueryOptions } from "react-query";

export const queryKeyFetchRutterConnection = "fetchRutterConnection";
export const queryKeyFetchRutterConnectionStatus =
  "fetchRutterConnectionStatus";

type RutterConnectionPostData = any;

export const fetchRutterConnection = (): Promise<string> =>
  api.post(routes.rutterConnectionFetch);

export const useFetchRutterConnection = () => {
  return useHfQuery<RutterConnectionPostData>(
    queryKeyFetchRutterConnection,
    routes.rutterConnectionFetch,
  );
};

export const useFetchRutterConnectionStatus = (
  options?: UseQueryOptions<any>,
) => {
  return useHfQuery<RutterConnectionPostData>(
    queryKeyFetchRutterConnectionStatus,
    routes.rutterConnectionFetchStatus,
    { options },
  );
};
