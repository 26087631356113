import { routes } from "network/routes";
import { useHfQuery } from "network/useHfQuery";
import { RutterSyncType } from "typings/models/rutter-transaction";

export const queryKey = "useRutterTransactionCountNotYetSynced";

type Response = {
  [key in RutterSyncType | "total"]: number;
};

export const useRutterTransactionCountNotYetSynced = (isEnabled = true) => {
  return useHfQuery<Response>(
    queryKey,
    routes.rutterTransactionCountNotYetSynced,
    {
      data: {},
      options: {
        enabled: isEnabled,
      },
    },
  );
};
