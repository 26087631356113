import { isBrowser } from "framer-motion";
import { QueryClient, useQueryClient as QC } from "react-query";

// Terrible gatsby hack
export const useQueryClient = !isBrowser
  ? () =>
      ({
        getQueryData: () => ({}),
        setQueryData: () => ({}),
      } as unknown as QueryClient)
  : QC;
