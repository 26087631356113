import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

interface IdleModalProps {
  isOpen: boolean;
  timeout: number;
  onStayActive: () => void;
  onClose: () => void;
  getRemainingTime: () => number;
}

export const IdleModal: React.FC<IdleModalProps> = ({
  isOpen,
  timeout,
  onStayActive,
  onClose,
  getRemainingTime,
}) => {
  const [remaining, setRemaining] = useState(timeout);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [[getRemainingTime]]);

  const seconds = remaining > 1 ? "seconds" : "second";

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="100%" maxW="500px">
        <ModalHeader mt={5} fontWeight="normal">
          Still there?
        </ModalHeader>
        <ModalBody>
          <Text fontSize="sm" mb={3}>
            Your session is about to expire.
            <br />
            For your security, this session will expire in {remaining} {seconds}{" "}
            due to inactivity. If you&apos;re still here, you can extend your
            session below. Otherwise, you&apos;ll be logged out.
          </Text>
          <Box mb={5} display="flex" justifyContent="end">
            <Button
              type="submit"
              colorScheme="brand"
              loadingText="Working"
              spinnerPlacement="start"
              onClick={onStayActive}
            >
              Yes, I&apos;m here
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
