import React, {
  ReactNode,
  useState,
  useContext,
  useMemo,
  createContext,
} from "react";

import { GlobalModal, ModalProps } from "components/GlobalModal";
import { Modal } from "components/Modal";
import { isBrowser } from "utils/env";

export type ModalQueueItem = ModalProps & { ModalComponent: React.FC<any> };
export type ModalQueue = Array<ModalQueueItem>;

interface ModalQueueState {
  queue: ModalQueue;
  updatedAt: number;
}

interface ModalQueueContext extends ModalQueueState {
  push: (props: any) => void;
  pop: () => void;
  clear: () => void;
}

const defaultModalQueueState = {
  queue: [],
  updatedAt: 0,
};
const defaultModalQueueContext = {
  ...defaultModalQueueState,
  push: () => {},
  pop: () => {},
  clear: () => {},
};

export const ModalsContext = createContext<ModalQueueContext>(
  defaultModalQueueContext,
);

export const ModalsConsumer = ModalsContext.Consumer;

export const useModals = () =>
  isBrowser ? useContext(ModalsContext) : defaultModalQueueContext;

export const ModalsProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<ModalQueueState>(defaultModalQueueState);

  const push = (modalQueueItem: ModalQueueItem) => {
    const { ModalComponent = Modal as React.FC<any>, ...modalProps } =
      modalQueueItem;
    setState({
      queue: [{ ModalComponent, ...modalProps }, ...state.queue],
      updatedAt: +new Date(),
    });
  };
  const pop = () => {
    setState({ queue: state.queue.slice(1), updatedAt: +new Date() });
  };

  const clear = () => {
    setState({ queue: [], updatedAt: +new Date() });
  };

  const memoizedContext = useMemo(
    () => ({
      queue: state.queue,
      updatedAt: state.updatedAt,
      push,
      pop,
      clear,
    }),
    [state.updatedAt],
  );

  return (
    <ModalsContext.Provider value={memoizedContext}>
      {children}
      <GlobalModal />
    </ModalsContext.Provider>
  );
};
