import { useToast } from "@chakra-ui/react";

type TToastParams = {
  title: string;
  duration?: number;
  description?: string;
};

export const useConfirmationToast = () => {
  const toast = useToast();

  return ({ title, duration = 3000, description = undefined }: TToastParams) =>
    toast({
      title,
      description,
      containerStyle: {
        zIndex: 50,
        background: "green",
        position: "relative",
        borderRadius: "5px",
        overflow: "hidden",
      },
      status: "success",
      duration,
      isClosable: true,
    });
};

export const useErrorToast = () => {
  const toast = useToast();

  return ({ title, duration = 3000, description = undefined }: TToastParams) =>
    toast({
      title,
      description,
      containerStyle: {
        zIndex: 50,
        background: "red",
        position: "relative",
        borderRadius: "5px",
        overflow: "hidden",
      },
      status: "error",
      duration,
      isClosable: true,
    });
};
