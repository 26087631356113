import React from "react";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  FlexProps,
  MenuItemProps,
} from "@chakra-ui/react";
import { useGetUserPermissions } from "network/User/get";
import { useRouter } from "next/router";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { IoMdPaperPlane } from "react-icons/io";
import { TfiArrowsHorizontal } from "react-icons/tfi";
import theme from "ui/Themes/default";

export const MoveMoneyButton: React.FC<any> = () => {
  const router = useRouter();

  const { data: permissions } = useGetUserPermissions();
  const hasMoneyMovementPermissions =
    permissions && permissions.money_movement > 0;

  if (!hasMoneyMovementPermissions) return null;

  const menuListStyle: Partial<FlexProps> = {
    overflow: "hidden",
    right: 0,
    boxShadow: "0 10px 24px rgb(65 65 90 / 13%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#1f1f30",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    position: "absolute",
    zIndex: 4,
  };

  const menuItemStyle: Partial<MenuItemProps> = {
    _hover: { bg: theme.colors.brand[25] },
    _focus: { bg: "transparent" },
    px: "28px",
    py: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "15px",
    whiteSpace: "nowrap",
  };

  const colors = {
    startColor: theme.colors.brand[450],
    endColor: theme.colors.brand[500],
  };

  return (
    <Menu placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              isOpen ? (
                <ChevronUpIcon boxSize="1.5em" />
              ) : (
                <ChevronDownIcon boxSize="1.5em" />
              )
            }
            variant="outline"
            isActive={isOpen}
            sx={{
              fontSize: "15px",
              height: "24px",
              lineHeight: "24px",
              minHeight: "20px",
              backgroundImage: `linear-gradient(33.21deg, ${colors.startColor} 10.28%, ${colors.endColor} 91.77%)`,
              borderRadius: "20px",
              boxShadow: "0 10px 24px rgb(65 65 90 / 13%)",
              color: "#fff",
              letterSpacing: "0.3px",
              padding: "8px 24px 8px 28px",
              appearance: "none",
              border: "none",
              boxSizing: "content-box",
              fontWeight: "600",
              outline: "transparent",
              display: "inline-flex",
              alignItems: "center",
              textDecoration: "none",
              whiteSpace: "nowrap",
              right: "0",
              top: { base: "35px", lg: 0 },
            }}
            _hover={{
              backgroundImage: `linear-gradient(33.21deg, ${colors.endColor} 10.28%, ${colors.endColor} 91.77%)`,
            }}
            _active={{
              backgroundImage: `linear-gradient(33.21deg, ${colors.endColor} 10.28%, ${colors.endColor} 91.77%)`,
            }}
          >
            Move Money
          </MenuButton>
          <MenuList {...menuListStyle} zIndex="popover">
            <MenuItem
              {...menuItemStyle}
              onClick={() => router.push("/money-movement/pay")}
            >
              <Icon as={IoMdPaperPlane} color="brand.450" mr={5} />
              <span>Pay Someone</span>
            </MenuItem>
            <MenuItem
              {...menuItemStyle}
              onClick={() => router.push("/accounts/linked")}
            >
              <Icon as={HiOutlineCurrencyDollar} color="brand.450" mr={5} />
              <span>Deposit or Withdraw Funds</span>
            </MenuItem>
            <MenuItem
              {...menuItemStyle}
              onClick={() => router.push("/money-movement/transfer")}
            >
              <Icon as={TfiArrowsHorizontal} color="brand.450" mr={5} />
              <span>Transfer Between Accounts</span>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
