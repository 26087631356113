import React from "react";

import posthog from "posthog-js";
import { v4 as uuidv4 } from "uuid";

import { posthogReady } from "./env";

interface State {
  id?: string;
  hasError: boolean;
}

interface Props {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      id: uuidv4(),
    };
  }

  // eslint-disable-next-line
  static getDerivedStateFromError(err: Error): State {
    // eslint-disable-next-line no-console
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (posthogReady) {
      posthog.capture("ERROR: ErrorBoundary", {
        error,
        errorId: this.state.id,
        errorInfo,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>{`Error id: ${this.state.id}`}</h1>;
    }

    return this.props.children;
  }
}
