/* eslint-disable @typescript-eslint/no-use-before-define */

import { useModals } from "context/modals";
import { api, getTokenExpirationTime, tokenRefresher } from "network";
import { useIdleTimer } from "react-idle-timer";

import { IdleModal } from "./IdleModal";

const timeout = 30 * 60 * 1000; // 30 minutes
const promptBeforeIdle = 2 * 60 * 1000; // 2 minute

export const IdleTimerComponent = () => {
  const { push, clear } = useModals();

  const onIdle = () => {
    api.prototype.logout({ returnTo: `${window.location.origin}/login` });
    clear();
  };

  const onActive = () => {
    clear();
  };

  const onPrompt = () => {
    push({
      ModalComponent: IdleModal,
      onStayActive: handleStayActive,
      onClose: () => {
        clear();
      },
      getRemainingTime,
      timeout,
    });
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  async function handleStayActive() {
    await tokenRefresher.fetch(); // Refresh the token
    activate(); // Reactivate the idle timer
  }

  if (typeof window !== "undefined" && "serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", async (event) => {
      if (event.data && event.data.action === "checkTokenExpiration") {
        // console.log("Check token expiration");
        const tokenExpirationTime = await getTokenExpirationTime();
        const currentTime = Date.now();
        // console.log("tokenExpirationTime", tokenExpirationTime);
        // console.log("currentTime", currentTime);

        // Check if the token expires in less than 2 minute
        if (tokenExpirationTime && tokenExpirationTime - currentTime < 120000) {
          const remainingTime = getRemainingTime();
          // console.log(
          //   "tokenExpirationTime - currentTime",
          //   tokenExpirationTime - currentTime,
          // );
          // console.log("remainingTime", remainingTime / 1000);
          if (remainingTime > promptBeforeIdle) {
            // console.log("Refresh token");
            await tokenRefresher.fetch(); // Refresh the token
          }
        }
      }
    });
  }

  return null;
};
