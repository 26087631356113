import React, { useContext } from "react";

import { HostfiUser } from "typings/shared";
// import { isBrowser } from "utils/env";
// import { THROW_ERROR } from "utils/throwError";

interface UserContextProps {
  user?: HostfiUser;
  setUser: (user: HostfiUser) => void;
}

export const UserContext = React.createContext<UserContextProps>({
  user: undefined,
  setUser: () => {},
});

export const useUserContext = () => {
  // if (isBrowser) {
  const { user } = useContext(UserContext);
  //   // Greatly complicates SSR - need to render the diff from when this isn't
  //   // defined via a useEffect update
  //   // if (!user) THROW_ERROR('No user in user context');
  return user || ({} as HostfiUser);
  // } else {
  //   return {} as HostfiUser;
  // }
};

export const useSetUserContext = () => {
  const { setUser } = useContext(UserContext);

  return setUser;
};
