/* eslint-disable react/prop-types */
import React, { ReactNode } from "react";

import { Box, ChakraComponent, Flex, Heading } from "@chakra-ui/react";

// @ts-ignore
export const Card: ChakraComponent<
  "div",
  { heading?: ReactNode; topRight?: ReactNode }
> = ({ children, heading, topRight, ...rest }) => (
  <Box
    className="ui-card"
    p={5}
    bg="white"
    w="100%"
    border="1px solid"
    borderColor="gray.200"
    borderRadius="2xl"
    boxShadow="0 0 3px rgb(4 4 52 / 9%), 0 4px 8px rgb(4 4 52 / 5%), 0 6px 12px rgb(4 4 52 / 2%)"
    {...rest}
  >
    {(heading || topRight) && (
      <Flex justifyContent="space-between">
        {typeof heading === "string" ? (
          <Heading size="md" mb={5}>
            {heading}
          </Heading>
        ) : (
          heading
        )}
        {topRight}
      </Flex>
    )}

    {children}
  </Box>
);
