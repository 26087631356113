import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import { isBrowser } from "utils/env";

import { api, mockApi } from ".";

export function useHfMutation<Input, Response>(
  url: string,
  dataFn?: (data: Input) => Input,
  options?: UseMutationOptions<Response, unknown, Input, unknown>,
  mock?: any,
) {
  if (!isBrowser)
    return Promise.resolve() as unknown as UseMutationResult<
      Response,
      unknown,
      Input,
      unknown
    >;

  if (mock) {
    mockApi.onPost(url).reply(mock[0], { response: mock[1] });
  }

  return useMutation<Response, unknown, Input, unknown>((data) => {
    const mutatedData = dataFn ? dataFn(data) : data;
    return api.post(url, mutatedData);
  }, options);
}
