import React, { ReactNode } from "react";

import { Box, Spinner } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { isBrowser } from "utils/env";

const DURATION = 0.33;
const DURATION_MS = 0.33 * 1000;

export function Loader({
  visible = false,
  children,
  hideChildrenWhileLoading = false,
}: {
  visible: boolean;
  children?: ReactNode;
  hideChildrenWhileLoading?: boolean;
}) {
  const container = {
    hidden: {
      opacity: 0,
      transition: { duration: DURATION, ease: "easeIn" },
    },
    show: {
      opacity: 1,
      transition: { duration: DURATION, ease: "easeOut" },
    },
  };

  React.useEffect(() => {
    if (isBrowser) {
      if (visible) {
        document.body.classList.add("fixed");
        window.scrollTo(0, 0);
      } else {
        setTimeout(() => {
          document.body.classList.remove("fixed");
        }, DURATION_MS);
      }
    }
  }, [visible]);

  React.useEffect(() => () => document.body.classList.remove("fixed"), []);

  return (
    <>
      <AnimatePresence>
        {visible && (
          <Box
            as={motion.div}
            variants={container}
            initial="hidden"
            animate="show"
            exit="hidden"
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="brand.900"
            position="absolute"
            zIndex="40"
          >
            <Spinner
              thickness="3px"
              speed="0.75s"
              emptyColor="#ddd"
              color="brand.500"
              width="2.6rem"
              height="2.6rem"
              top={0}
              left={0}
            />
          </Box>
        )}
      </AnimatePresence>
      {hideChildrenWhileLoading && visible ? null : children}
    </>
  );
}
