import React, {
  useEffect,
  useCallback,
  useState,
  ReactNode,
  useMemo,
} from "react";

import { useAuth0, User } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import { GlobalModal } from "components/GlobalModal";
import { IdleTimerComponent } from "components/IdleTimer/IdleTimerComponent";
import { AuthProvider } from "context/auth";
import { ModalsProvider } from "context/modals";
import { UserContext } from "context/user";
import jwtDecode from "jwt-decode";
import { fetchAccount, queryKeyFetchAccount } from "network/Account/fetch";
import { routePaths } from "network/routes";
import { fetchUserWithAuth0UserId } from "network/User/fetchUserWithAuth0UserId";
import { useGetUserPermissions } from "network/User/get";
import Head from "next/head";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import { Account, HostfiUser } from "typings/shared";
import { Loader } from "ui/Loader";
import {
  featureRoutes,
  userRoutes,
  isAuthorized as getIsAuthorized,
  Route,
} from "ui/Nav/Authenticated/Nav";
import defaultTheme from "ui/Themes/default";
import {
  configureAuthMethods,
  getAccessToken,
  useRouteRequiresAuth,
} from "utils/auth";
import { determineNextPath } from "utils/determineNextPath";
import { isBrowser } from "utils/env";
import { ErrorBoundary } from "utils/ErrorBoundary";
import { THROW_ERROR } from "utils/throwError";
import { useIsLoaded } from "utils/useNoSsr";
import { useQueryClient } from "utils/useQueryClient";
import "ui/styles/index.scss";

// TODO: move to useAuth
function AuthWrap({ children }: { children: ReactNode }) {
  const auth0 = useAuth0();

  useEffect(() => configureAuthMethods(auth0), []);

  const routeRequiresAuth = useRouteRequiresAuth();
  const { push, pathname: route } = useRouter();
  const queryClient = useQueryClient();
  const [hostfiUser, setHostfiUser] = useState<HostfiUser>();
  const accessToken = getAccessToken();
  const { data: permissions } = useGetUserPermissions();
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const routeObj = [...featureRoutes, ...userRoutes].find(
    (el) => el.pathname === route,
  ) as Route;

  const { isAuthenticated, isLoading: useAuthLoading, user } = auth0;

  const [bootstrapComplete, setBootstrapComplete] = useState(
    !routeRequiresAuth || (useAuthLoading && !isAuthenticated),
  );

  const setBootstrapCompleteTrue = useCallback(
    () => setTimeout(() => setBootstrapComplete(true), 500),
    [],
  );

  async function redirectToIndex() {
    await push(routePaths.home);
    setBootstrapCompleteTrue();
  }

  async function redirectToUnauthorized() {
    await push(routePaths.unauthorized);
    setBootstrapCompleteTrue();
  }

  useEffect(() => {
    // Bootstrap will run multple times based on user state
    async function bootstrap() {
      const parsedToken = accessToken
        ? (jwtDecode(accessToken) as User)
        : undefined;
      const sub = user?.sub || parsedToken?.sub;
      // MOVE THIS TO WHERE IT ONLY GETS RUN ONCE
      if (isBrowser && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
          api_host: "https://app.posthog.com",
          enable_recording_console_log: true,
        });
      }

      if (isAuthenticated || sub) {
        const hfUser = await fetchUserWithAuth0UserId(sub);
        if (!hfUser) THROW_ERROR("No user data");

        if (isBrowser && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
          posthog.identify(hfUser.email);
        }

        const stripeAccount = await fetchAccount();
        setAccount(stripeAccount);
        if (!stripeAccount)
          THROW_ERROR("No stripe account data", { silent: true });

        queryClient.setQueryData(queryKeyFetchAccount, {
          ...stripeAccount,
        });

        const { nextPath } = await determineNextPath(
          queryClient,
          route,
          stripeAccount,
          hfUser,
        );

        if (nextPath && nextPath !== route) {
          await push(nextPath);
        }

        setHostfiUser(hfUser);
        setBootstrapCompleteTrue();
      } else if (routeRequiresAuth) {
        redirectToIndex();
      }
    }

    if (!useAuthLoading && !bootstrapComplete) bootstrap();
  }, [isAuthenticated, bootstrapComplete, useAuthLoading]);

  useEffect(() => {
    if (
      routeObj &&
      permissions &&
      account &&
      !getIsAuthorized(routeObj, permissions, account.is_accounting_enabled)
    )
      redirectToUnauthorized();
    else setIsAuthorized(true);
  }, [routeObj, permissions, account]);

  if (bootstrapComplete && !useAuthLoading) {
    if (isAuthenticated || accessToken) {
      if (!hostfiUser) {
        setBootstrapComplete(false);
      }
    } else if (routeRequiresAuth) {
      setBootstrapComplete(false);
    }
  }

  const userContext = useMemo(
    () => ({
      user: hostfiUser,
      setUser: setHostfiUser,
    }),
    [hostfiUser, setHostfiUser],
  );

  return (
    <Loader
      visible={
        (isAuthenticated && !hostfiUser) ||
        !bootstrapComplete ||
        (routeObj && !isAuthorized) ||
        useAuthLoading
      }
      hideChildrenWhileLoading
    >
      <UserContext.Provider value={userContext}>
        {children}
        <GlobalModal />
      </UserContext.Provider>
    </Loader>
  );
}

type NextAppPageProps = any;
type NextAppProps = {
  Component: React.FC<NextAppPageProps>;
  pageProps: NextAppPageProps;
};

function App({ Component, pageProps }: NextAppProps) {
  const isLoaded = useIsLoaded();
  // Partial adoption of SSR suggestions:
  // https://react-query-v3.tanstack.com/guides/ssr#using-hydration
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            staleTime: Infinity,
          },
        },
      }),
  );

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/tokenServiceWorker.js")
        .then((registration) => {
          console.log("Service worker registration successful:", registration);
        })
        .catch((error) => {
          console.log("Service worker registration failed:", error);
        });
    }
  }, []);

  // Stopgap measure: sidestep all Hydration errors in development
  if (!isLoaded) return <></>;

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="robots" content="noindex" />
        <title>{process.env.NEXT_PUBLIC_WINDOW_TITLE || "Hostfi"}</title>
        <link rel="shortcut icon" href="/images/fancy-favicon.png" />
      </Head>
      <ChakraProvider resetCSS theme={defaultTheme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            {/* Leaving in place for future optiomizations */}
            {/* <Hydrate state={pageProps.dehydratedState}> */}
            <AuthProvider>
              <IntlProvider locale="en" defaultLocale="en">
                <AuthWrap>
                  <ModalsProvider>
                    <IdleTimerComponent />
                    <Component {...pageProps} />
                  </ModalsProvider>
                </AuthWrap>
              </IntlProvider>
            </AuthProvider>
            {/* </Hydrate> */}
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// App.getInitialProps = async (appContext: any) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps };
// };

export default App;
