import React from "react";

import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  Heading, // TODO: Style ModalHeader the same
  Flex,
} from "@chakra-ui/react";

// TODO: combine/replace with GlobalModal
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
}
export const Modal: React.FC<
  ModalProps & {
    body: string;
    confirmButtonText?: string;
    secondOptionText?: string;
    onClickSecondOption: () => void;
    hasSecondOption?: boolean;
    xButton?: boolean;
  }
> = ({
  title,
  body,
  isOpen,
  onClose,
  confirmButtonText = "Close",
  secondOptionText = "Add Another",
  onClickSecondOption,
  hasSecondOption = false,
  xButton = true,
}) => (
  <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      {xButton && <ModalCloseButton />}
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        {hasSecondOption ? (
          <Flex gap={5} justifyContent="center" mx="auto" mb={3}>
            <Button variant="outline" onClick={onClose}>
              {confirmButtonText}
            </Button>
            <Button colorScheme="brand" onClick={onClickSecondOption}>
              {secondOptionText}
            </Button>
          </Flex>
        ) : (
          <Button colorScheme="brand" onClick={onClose}>
            {confirmButtonText}
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  </ChakraModal>
);

export const ChildrenModal = ({
  children,
  isOpen,
  onClose,
  title,
}: ModalProps & { children: React.ReactNode }) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="100%" maxW="600px">
        <ModalCloseButton />
        <ModalBody px={5} pt={14} pb={10}>
          {title && <Heading mb="5">{title}</Heading>}
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
