import { routes } from "network/routes";
import { useHfQuery } from "network/useHfQuery";

export const queryKey = "usePmsTransactionCountNotYetSynced";

export const usePmsTransactionCountNotYetSynced = () => {
  return useHfQuery<{
    expense: number;
    payment: number;
    total: number;
  }>(queryKey, routes.pmsTransactionCountNotYetSynced, {
    data: {},
  });
};
