import React from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  ModalContentProps,
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalHeader,
  Heading,
} from "@chakra-ui/react";
import { useModals } from "context/modals";

export interface ModalProps {
  isCentered?: boolean;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  headerChildren?: React.ReactNode;
  footerChildren?: React.ReactNode;
  modalContentProps?: ModalContentProps;
  modalHeaderProps?: ModalHeaderProps;
  modalBodyProps?: ModalBodyProps;
  modalFooterProps?: ModalFooterProps;
  hideCloseButton?: boolean;
  // old Modal Props
  title?: string;
  body?: string;
}

export const GlobalModal: React.FC<any> = () => {
  const { queue = [], pop } = useModals();

  if (queue.length === 0) return null;

  const [topOfQueue] = queue;
  const { ModalComponent = null, ...modalProps } = topOfQueue;

  const {
    isCentered = true,
    onClose = pop,
    children,
    headerChildren,
    footerChildren,
    modalContentProps = null,
    modalHeaderProps = null,
    modalBodyProps = null,
    modalFooterProps = null,
    hideCloseButton = false,
    title = "",
    body = "",
    ...customModalProps
  } = modalProps;

  if (ModalComponent)
    return (
      <ModalComponent
        {...{
          isCentered,
          onClose,
          children,
          headerChildren,
          footerChildren,
          modalContentProps,
          modalHeaderProps,
          modalBodyProps,
          modalFooterProps,
          hideCloseButton,
          title,
          body,
          ...customModalProps,
        }}
        isOpen
      />
    );

  return (
    <Modal {...{ isOpen: true, onClose, isCentered }}>
      <ModalOverlay />
      <ModalContent width="100%" maxW="600px" {...modalContentProps}>
        {!hideCloseButton && <ModalCloseButton />}
        {(title || headerChildren) && (
          <ModalHeader {...modalHeaderProps}>
            {title && title} {!title && headerChildren}
          </ModalHeader>
        )}
        <Heading>Foo</Heading>
        <ModalBody px={5} pt={14} pb={0} {...modalBodyProps}>
          {body && body}
          {!body && children}
        </ModalBody>
        {footerChildren && (
          <ModalFooter {...modalFooterProps}>{footerChildren}</ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
