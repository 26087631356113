import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Heading } from "@chakra-ui/react";
import { useGetUserPermissions } from "network/User/get";
import Image from "next/image";
import Link from "next/link";
import { getFirstAuthorizedRoute } from "utils/determineNextPath";

export function HostFiLogo() {
  const { data: permissions } = useGetUserPermissions();
  const { isAuthenticated = false } = useAuth0();
  const [href, setHref] = useState<string>("/");

  useEffect(() => {
    if (isAuthenticated && permissions)
      setHref(getFirstAuthorizedRoute(permissions));
  }, [isAuthenticated, permissions]);

  return (
    <Heading as="h1" mr={5}>
      <Link href={href} passHref>
        <a href={href}>
          <Image
            src="/images/hostfi.svg"
            alt="Hostfi"
            width={650 / 7}
            height={328 / 7}
          />
        </a>
      </Link>
    </Heading>
  );
}
