import React, { ReactNode } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { MoveMoneyButton } from "components/MoveMoneyButton";
import { NavAuthenticated } from "ui/Nav/Authenticated/Nav";

export const AuthenticatedLayout: React.FC<{ children?: ReactNode }> = ({
  children,
}) => (
  <Box position="relative" h="100vh">
    <NavAuthenticated />
    <Box bg="orange.100" pl={{ base: 0, lg: 48 }} pb={1} minHeight="100vh">
      <Flex
        mx={{ base: 5, lg: 20 }}
        h={{ base: 5, lg: 20 }}
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <MoveMoneyButton />
      </Flex>
      <Box
        maxWidth="100%"
        mx={{ base: 5, lg: 20 }}
        mb={{ base: 5, lg: 20 }}
        pt={{ base: 10, lg: 0 }}
      >
        {children}
      </Box>
    </Box>
  </Box>
);
