import { theme as defaultTheme } from "@chakra-ui/react";
import merge from "lodash/merge";

const theme = merge({}, defaultTheme, {
  styles: {
    global: {
      "html, body": {
        fontSize: "14px",
        color: "gray.900",
      },
      "h1, h2, h3, h4, h5, h6": {
        fontWeight: "normal",
      },
    },
  },
  colors: {
    green: "#1EA382",
    red: "#dc5151",
    brand: {
      25: "#F8E1E1",
      400: "#E55F5F",
      450: "#C43838",
      500: "#B23C3C",
      600: "#4A1212",
      700: "#4A1212",
      800: "#4A1212",
      900: "#300606",
    },
    gray: {
      100: "#F9F9F9",
      200: "#EAEAEA",
      400: "#828282",
      500: "#676879",
    },
    orange: {
      100: "#F0EBE2",
    },
  },
  fonts: {
    heading: `'Playfair Display', sans-serif`,
    body: `'Archivo', sans-serif`,
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "32px",
    "5xl": "48px",
    "6xl": "64px",
  },
  components: {
    Input: {
      defaultProps: {
        errorBorderColor: "gray.200",
        focusBorderColor: "gray.200",
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: "normal",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "normal",
      },
      variants: {
        outline: {
          border: "1px solid",
          borderColor: "gray.200",
          color: "gray.500",
          _hover: {
            bg: "gray.200",
          },
          _active: {
            bg: "gray.100",
          },
        },
      },
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default theme;
