import { useEffect, useState } from "react";

type TReturnsBoolean = () => boolean;

export const useIsLoaded: TReturnsBoolean = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  });

  return isLoaded;
};
