import { api } from "network";
import { routes } from "network/routes";
import { useHfQuery } from "network/useHfQuery";
import { HostfiUserPermissions } from "typings/shared";

export const QUERY_KEY_USER = "user";
export const QUERY_KEY_USER_PERMISIONS = "user_permisions";

export const useGetUser = () =>
  useHfQuery<boolean>(QUERY_KEY_USER, routes.user);

export const useGetUserPermissions = () =>
  useHfQuery<HostfiUserPermissions>(QUERY_KEY_USER, routes.userFetchPermisions);

export const fetchUserPermisions = (): Promise<HostfiUserPermissions> =>
  api.post(routes.userFetchPermisions, {});
