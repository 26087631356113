import { routes } from "network/routes";
import { useHfMutation } from "network/useHfMutation";

type RutterPublicToken = { public_token: string };

export const useRutterCreateWithPublicToken = () => {
  return useHfMutation<RutterPublicToken, any>(
    routes.rutterConnectionCreateWithPublicToken,
    (data) => data,
  );
};
