import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { isBrowser } from "utils/env";

/**
 * Isolating Auth0Provider for now. Bring in bootstrapping that happens
 * in _app.tsx later and combine that with the useAuth hook to provide
 * those fields currently consumed by other nested contexts.
 */
export function AuthProvider({ children }: { children: React.ReactNode }) {
  return (
    <Auth0Provider
      cacheLocation="localstorage"
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ""}
      useRefreshTokens
      redirectUri={
        isBrowser
          ? window.location.origin +
            (!process.env.NEXT_PUBLIC_LOGIN_EXPERIENCE ? "/login" : "/")
          : ""
      }
    >
      {children}
    </Auth0Provider>
  );
}
