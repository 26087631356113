import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { getAccessToken } from "utils/auth";
import { isBrowser } from "utils/env";

import { api, mockApi } from ".";

type TUseHfQueryConfig<R> = {
  data?: any;
  auth?: boolean;
  options?: UseQueryOptions<R>;
  mock?: any;
};

export const useHfQuery = <R = any>(
  key: string | readonly unknown[],
  url: string,
  config?: TUseHfQueryConfig<R>,
) => {
  if (!isBrowser) return Promise.resolve() as unknown as UseQueryResult<R>;

  const { auth = true } = config || {};

  const authCheck = auth ? !!getAccessToken() : true;

  const enabled =
    typeof config?.options?.enabled === "boolean"
      ? config?.options?.enabled
      : authCheck;

  if (config?.mock) {
    mockApi
      .onPost(url)
      .reply(() => [config.mock[0], { response: config.mock[1] }]);
  }

  return useQuery<R>(key, () => api.post(url, config?.data || {}), {
    ...(config?.options || {}),
    enabled,
  });
};
